import styled from '@emotion/styled';
import React, { memo } from 'react';
import { fill, fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { useAnalysts, useIntroduction, usePhotoUrl, } from '~/pages/asiajye_promotion/function/useStockForumData';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { createUnorderedList, createLineBreak, removeChineseSpace, removeCommas, } from '~/pages/asiajye_promotion/function/createTextMarkup';
import { scrollbar2Css } from '~/css/scrollbarCss';
const emptyAnalysts = {
    name: '',
    channelName: '',
    programName: '',
    facebookLink: '',
    lineLink: '',
    instagramLink: '',
    telegramLink: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
};
const emptyIntroductions = {
    title: '',
    content: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
};
export const AnalystInfoContent = memo(function AnalystInfoContent() {
    const { isPhone } = useMedia();
    const dataIntroductions = useIntroduction('Ronaldo')?.data?.data?.map(s => s?.attributes) ?? [
        emptyIntroductions,
    ];
    const dataAnalysts = useAnalysts('Ronaldo')?.data?.data?.map(s => s.attributes) ?? [emptyAnalysts];
    const photoUrl = usePhotoUrl()?.data ?? '';
    // if (!photoUrl) return null
    return (<styleds.container isPhone={isPhone}>
      <styleds.header>
        <styleds.image src='asiajye_promotion/分析師介紹.png'/>
        {/* <span>分析師介紹</span> */}
      </styleds.header>
      <styleds.body>
        <styleds.content>
          <styleds.infoImg>
            <styleds.imageWrapper>
              <styleds.personImg src={photoUrl}/>
            </styleds.imageWrapper>

            <styleds.analystInfoText>分析師:</styleds.analystInfoText>
            <div>
              {dataAnalysts?.map(s => {
            const revisedText = removeChineseSpace(s.name);
            if (typeof revisedText !== 'string')
                return;
            return createLineBreak(revisedText);
        })}
            </div>
            <styleds.analystInfoText>節目名稱:</styleds.analystInfoText>
            <div>
              {dataAnalysts?.map(s => {
            const revisedText = removeCommas(s.channelName);
            return createLineBreak(revisedText);
        })}
            </div>

            <styleds.socialAppIconContent>
              <a href='https://line.me/R/ti/p/%40775hhppa'>
                <img width='36' height='36' src='https://img.icons8.com/pulsar-color/48/line-me.png' alt='line-me'/>
              </a>
              <a href='https://www.youtube.com/@user-id1rj2wz7p'>
                <img width='36' height='36' src='https://img.icons8.com/doodle/48/youtube-play--v1.png' alt='youtube-play--v1'/>
              </a>
              <a href='https://docs.google.com/forms/d/1zxvZiGIMXeiZ9BI1tFVxxgu7_wtV391Gk01fjMWLBM8/viewform?edit_requested=true'>
                <img width='36' height='36' src='https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-call-office-and-office-supplies-flaticons-lineal-color-flat-icons-2.png' alt='external-call-office-and-office-supplies-flaticons-lineal-color-flat-icons-2'/>
              </a>
            </styleds.socialAppIconContent>
          </styleds.infoImg>
          <styleds.infoText>
            {dataIntroductions?.map((s, index) => {
            return (<Item key={index} data={s}/>);
        })}
          </styleds.infoText>
        </styleds.content>
      </styleds.body>
    </styleds.container>);
});
const Item = memo(function Item(props) {
    const newText = createUnorderedList(props.data.content);
    return (<div css={css `
        width: 100%;
        padding: 4px;
      `}>
      <styleds.infoHeader>
        <styleds.starIcon src='asiajye_promotion/kung-fu.png'/>
        {props.data.title}
      </styleds.infoHeader>
      <styleds.infobody>{newText}</styleds.infobody>
    </div>);
});
const styleds = {
    //共用css
    container: styled.div `
    ${flex.v.crossCenter};
    ${scrollbar2Css};
    /* 避免因排版而看不到內容的保險設定 */
    overflow: auto;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
    /* 手機版版位寬度為100vw，使其比例為1：2且不會因為高度過小而產生滑桿*/
    max-height: ${props => (props.isPhone ? '200vw' : '')};
    min-height: 250px;
  `,
    header: styled.div `
    ${fill_vertical_all_center};
    /* 固定表頭 */
    position: sticky;
    top: -9px;
    /* 在最上方，讓表頭不會被大頭照遮住 */
    z-index: 999;
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
  `,
    image: styled.img `
    max-height: 64px;
    border-radius: 4px;
  `,
    analystInfoText: styled.span `
    font-weight: 600;
    text-align: center;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    /* height: calc(100% - 40px); */
    //display: grid;
    //grid-template-columns: 40% 60%;
    /* padding: 8px; */
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
    //共用css
    content: styled.div `
    ${fill};
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 4px;
  `,
    starIcon: styled.img `
    width: 36px;
  `,
    infoImg: styled.div `
    ${fill_vertical_all_center}
    background-color:#fafafa;
    /* font-weight: 600; */
    gap: 8px;
  `,
    infoText: styled.div `
    /* 文章內容置中 */
    ${fill_vertical_all_center}
    background-color:#fafafa;
  `,
    infoHeader: styled.div `
    ${fill_horizontal_cross_center};
    height: 24px;
    font-weight: 600;
  `,
    infobody: styled.div `
    padding: 4px;
  `,
    imageWrapper: styled.div `
    width: 80%;
    position: relative;
    ::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  `,
    personImg: styled.img `
    position: absolute;
    top: 0;

    left: 0;

    width: 100%;
    height: 100%;
    /* 圖片可以置中且保持比例縮放於指定框尺寸 */
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #459ada;
  `,
    linkButtonContent: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    gap: 8px;
    padding: 0 16px;
  `,
    socialAppIconContent: styled.div `
    ${flex.wrap.allCenter}
    height: 48px;
  `,
    linkButton: styled.div `
    ${fill_horizontal_all_center};
    width: 50%;
    height: 40px;
    border-radius: 4px;
    background-color: ${props => props.bgFill};
    color: #ffffff;
  `,
};
