import useSWR from 'swr';
// 注意:
// cms會給{meta:[], data:[]}
//使用上 useStockrecords(agent).data.data
//拿分析師相關基本資料
export const useAnalysts = (agentName) => {
    const url = 'https://cms.futures-op.com/api/analysts?filters%5BcreatedBy%5D%5Bfirstname%5D%5B$eq%5D=' +
        agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//拿分析師介紹欄位
export const useIntroduction = (agentName) => {
    const url = 'https://cms.futures-op.com/api/introductions?populate=*' + agentName;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    return {
        data: res.data,
        isLoading: !res.data && !res.error,
    };
};
//拿分析師照片
export const usePhotoUrl = () => {
    const url = 'https://cms.futures-op.com/api/analysts?populate=*';
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    let photoUrl = '';
    if (res.data?.data && Array.isArray(res.data.data) && res.data.data.length > 0) {
        photoUrl =
            'https://cms.futures-op.com' +
                (res.data.data[0]?.attributes?.profilePicture?.data?.attributes?.formats?.thumbnail?.url ??
                    '');
    }
    return {
        data: photoUrl,
        isLoading: !res.data && !res.error,
    };
};
//拿每月績效的文字與照片連結
export const useStockPerformance = () => {
    // 設定取用lastUpdated降冪排序
    const url = 'https://cms.futures-op.com/api/daily-market-performances?sort[0]=lastUpdated:desc&populate=*';
    const res = useSWR(url, errorEmptyArrayFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 0,
    });
    let formattedRes;
    if (res.data && Array.isArray(res.data.data)) {
        formattedRes = res.data.data.map(item => {
            return {
                lastUpdated: item.attributes.lastUpdated,
                content: item.attributes.content,
                thumbnail: 'https://cms.futures-op.com' +
                    (item.attributes.Picture.data?.attributes?.formats?.thumbnail?.url || ''),
                small: 'https://cms.futures-op.com' +
                    (item.attributes.Picture.data?.attributes?.formats?.small?.url || ''),
            };
        });
    }
    return {
        data: formattedRes,
        isLoading: !res.data && !res.error,
    };
};
//----------------------------------------------------------------
const fetcher = async (url) => {
    const res = await fetch(url);
    if (res.status >= 400)
        throw new Error();
    return res.json();
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
